import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledTypography = styled(Typography)`
  color: #78b928;
`;

const Title = ({ text }) => {
  return <StyledTypography variant="h5">{text}</StyledTypography>;
};

export default Title;
