import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';

import './services/i18n';
import './index.css';

import Theme from './theme';
import App from './app';
import { SnackbarProvider } from './context/snackbarContext';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as serviceWorker from './serviceWorker';

const FullscreenLoader = () => (
  <Box
    display="flex"
    height="100vh"
    width="100vw"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FullscreenLoader />}>
      <Router>
        <ThemeProvider theme={Theme}>
          <StyledThemeProvider theme={Theme}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
