import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuth } from './hooks/firebaseAuth';

import PrivateRoute from './routes/private';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import ExpenseApprove from './pages/expenseApprove';
import ExpenseEdit from './pages/expenseEdit';
import Expense from './pages/expense';
import UserPage from './pages/user';
import NavigationBar from './components/navigation/navbar';

import UserContext from './context/userContext';

const App = () => {
  const [user, loading] = useAuth(firebase.auth());
  if (loading) {
    return (
      <Box
        display="flex"
        height="100vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        signOut: () => firebase.auth().signOut(),
      }}
    >
      <Switch>
        <Route path="/login" component={LoginPage} />

        <Route
          path="/user"
          render={({ match: { url } }) => (
            <>
              <NavigationBar />
              <Route path={`${url}/:id`} component={UserPage} exact />
            </>
          )}
        />
        <Route
          path="/expenses"
          render={({ match: { url } }) => (
            <>
              <NavigationBar />
              <Switch>
                <PrivateRoute
                  path={`${url}/:id`}
                  comp={Expense}
                  exact
                />
                <PrivateRoute
                  path={`${url}/:id/approve`}
                  comp={ExpenseApprove}
                  exact
                />
                <PrivateRoute
                  path={`${url}/:id/edit`}
                  comp={ExpenseEdit}
                  exact
                />
              </Switch>
            </>
          )}
        />
        <Route
          path="/"
          render={({ match: { url } }) => (
            <>
              <NavigationBar />
              <PrivateRoute path={`${url}/`} comp={HomePage} exact />
            </>
          )}
        />
      </Switch>
    </UserContext.Provider>
  );
};
export default App;
