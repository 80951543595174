import React, { useCallback, useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import TestingReminder from './testingReminder';
import ColumnHeadCell from './columnHeadCell';
import LinkButton from '../../components/buttons/linkButton';
import ConfirmModal from '../../components/confirmModal';
import InstructionModal from '../../components/instructionModal';
import ExpenseState from '../../components/expenseState';
import VisitorForm from '../../components/form/visitorExpenseNotification';
import Title from '../../components/title/title';
import { Search, Dropdown } from '../../components/inputs';

import useExpensesApi from '../../hooks/useExpensesApi';
import useInstructionsApi from '../../hooks/useInstructionsApi';
import useUsersApi from '../../hooks/useUsersApi';

import { useSnackbar } from '../../context/snackbarContext';
import {
  getGroup,
  getGroupEmails,
  getTotalAmount,
  getTotalDistance,
} from '../../utils/utils';

const HeroBox = styled(Box)`
  background: url('/background.jpg') no-repeat center center fixed;
  background-size: cover;
  background-attachment: scroll;
  overflow: auto;
`;

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState();
  const [loadingMore, setLoadingMore] = useState();
  const [expenses, setExpenses] = useState();
  const [instruction, setInstruction] = useState();
  const [user, setUser] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [instructionModalOpen, setInstructionModalOpen] = useState();
  const [selectedExpense, setSelectedExpense] = useState();
  const [filters, setFilters] = useState([]);
  const { showSnackbar } = useSnackbar();

  const expenseStates = t('state', { returnObjects: true });
  const expenseTypes = t('expenseType', { returnObjects: true });
  const { getAll, deleteExpense } = useExpensesApi();
  const { getMyProfile } = useUsersApi();
  const {
    get: getInstruction,
    save: saveInstruction,
    markAsRead,
  } = useInstructionsApi();

  const getExpenses = useCallback(async () => {
    setLoading(true);
    const user = await getMyProfile();
    if (user.role !== 'user') {
      const data = await getAll();
      setExpenses(data);
    }
    setUser(user);
    setLoading(false);
  }, [getAll, getMyProfile]);

  useEffect(() => {
    getExpenses();
  }, [getExpenses]);

  useEffect(() => {
    const fetch = async () => {
      const instruction = await getInstruction();
      setInstruction(instruction);
    };
    fetch();
  }, [getInstruction]);

  const onFilterChange = useCallback(
    async (filters) => {
      setLoadingMore(true);
      const data = await getAll(null, filters);
      setExpenses(data);
      setLoadingMore(false);
    },
    [getAll],
  );

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const handleFilterChange = (setState, { key, value }) => {
    setState((previousFilters) => {
      const filterIndex = previousFilters.findIndex(
        (filter) => filter.type === key,
      );
      if (filterIndex > -1) {
        previousFilters.splice(filterIndex, 1);
      }
      if (!key || !value) {
        return [...previousFilters];
      }
      const filter = {
        type: key,
        filters: [],
      };
      if (key === 'group') {
        const groupEmails = getGroupEmails(value).map((email) => ({
          key,
          value: email,
        }));
        filter.filters = [
          { key, value },
          { key: 'handler', value },
          ...groupEmails,
        ];
        return [...previousFilters, filter];
      } else {
        filter.filters = [{ key, value }];
      }
      return [...previousFilters, filter];
    });
  };

  const onLoadMore = async () => {
    setLoadingMore(true);
    const data = await getAll(expenses.nodes.length, filters);
    const newExpenses = {
      nodes: expenses.nodes.concat(data.nodes),
      pageInfo: data.pageInfo,
    };
    setExpenses(newExpenses);
    setLoadingMore(false);
  };

  const onDeleteExpense = async () => {
    await deleteExpense(selectedExpense.id);
    const newExpenses = {
      nodes: expenses.nodes.filter(
        (e) => e.id !== selectedExpense.id,
      ),
      pageInfo: expenses.pageInfo,
    };
    setExpenses(newExpenses);
    setSelectedExpense(null);
  };

  const onDeleteExpenseClick = async (expense) => {
    setSelectedExpense(expense);
    setConfirmDelete(true);
  };

  const onInstructionModalClosed = async (text) => {
    if (user.role === 'admin') {
      const instruction = await saveInstruction({ text });
      setInstruction(instruction);
      setInstructionModalOpen(false);
      showSnackbar({
        id: 'approved',
        type: 'success',
        message: t('instructionModal.saveSuccess'),
      });
    } else {
      setInstruction({ ...instruction, read: true });
      setInstructionModalOpen(false);
    }
  };
  const rows =
    expenses && expenses.nodes.length ? expenses.nodes : [];

  const hasMore =
    expenses && expenses.nodes.length < expenses.pageInfo.count;

  return (
    <Box display="flex" flexDirection="column" width={'100%'}>
      <HeroBox display="flex" flex={1}>
        <Box
          display="flex"
          p={[2, 2, 10]}
          flexDirection={['column-reverse', 'column-reverse', 'row']}
        >
          <Box mr={process.env.REACT_APP_DEMO_GROUPS ? 2 : 0}>
            <Paper>
              <Box display="flex" flexDirection="column" px={1}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={() => setInstructionModalOpen(true)}
                  >
                    <Tooltip title={t('home.instructionsTooltip')}>
                      <Badge
                        variant={
                          instruction && !instruction.read && 'dot'
                        }
                        color="secondary"
                      >
                        <HelpOutlineIcon color="primary" />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  p={3}
                  pt={0}
                  mt={-2}
                >
                  <Title
                    text={t('form.visitorExpenseNotification.title')}
                  />
                  <VisitorForm onSubmitted={getExpenses} />
                </Box>
              </Box>
            </Paper>
          </Box>
          {process.env.REACT_APP_DEMO_GROUPS && <TestingReminder />}
        </Box>
      </HeroBox>
      <Box pt={5} pb={2} px={[2, 2, 10]}>
        {user && user.role !== 'user' && (
          <Title text={t('home.expenseRows')} />
        )}
        {loading && (
          <Box
            display="flex"
            mt={2}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && user && user.role !== 'user' && (
          <Box mt={2}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <ColumnHeadCell>
                    {t('home.guest')}
                    <Search
                      label={t('search')}
                      id={'expenses-guest'}
                      onChange={(value) =>
                        handleFilterChange(setFilters, {
                          key: 'visitorEmail',
                          value,
                        })
                      }
                    />
                  </ColumnHeadCell>
                  <ColumnHeadCell>
                    {t('home.group')}
                    <Search
                      label={t('search')}
                      id={'expenses-group'}
                      onChange={(value) =>
                        handleFilterChange(setFilters, {
                          key: 'group',
                          value,
                        })
                      }
                    />
                  </ColumnHeadCell>
                  <ColumnHeadCell>
                    {t('home.state')}
                    <Dropdown
                      label={t('home.state')}
                      id={'expenses-state'}
                      fields={[
                        { label: '-', value: '' },
                        ...Object.keys(expenseStates).map(
                          (state) => ({
                            label: expenseStates[state],
                            value: state,
                          }),
                        ),
                      ]}
                      onChange={(value) =>
                        handleFilterChange(setFilters, {
                          key: 'state',
                          value,
                        })
                      }
                    />
                  </ColumnHeadCell>
                  <ColumnHeadCell>
                    {t('home.amount')}
                    <Dropdown
                      label={t('form.expense.type')}
                      id={'expenses-type'}
                      fields={[
                        { label: '-', value: '' },
                        ...Object.keys(expenseTypes).map((type) => ({
                          label: expenseTypes[type],
                          value: type,
                        })),
                      ]}
                      onChange={(value) =>
                        handleFilterChange(setFilters, {
                          key: 'expenses.type',
                          value,
                        })
                      }
                    />
                  </ColumnHeadCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.visitorEmail}
                      <Box display="flex">
                        {row.startTime && (
                          <Box mr={0.5} mb={0.5}>
                            <Chip
                              size="small"
                              label={`${new Date(
                                row.startTime,
                              ).toLocaleDateString(i18n.language)}`}
                            />
                          </Box>
                        )}

                        {row.visitorType && (
                          <Chip
                            size="small"
                            label={t(
                              `visitorType.${row.visitorType}`,
                            )}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.handler ||
                        getGroup(row.group) + ` (${row?.group})`}
                    </TableCell>
                    <TableCell align="left">
                      <ExpenseState
                        state={row.state}
                        date={row.created}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{getTotalAmount(row)}</Typography>
                      {(row.expenses || []).filter(
                        (expense) =>
                          expense.type === 'kilometreAllowance',
                      ).length > 0 && (
                        <Typography>
                          {getTotalDistance(row)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box display="flex">
                        <Box mr={2}>
                          <Button
                            disabled={
                              user && user.role === 'admin'
                                ? false
                                : row.state === 'approved'
                            }
                            variant="text"
                            onClick={() => onDeleteExpenseClick(row)}
                          >
                            {t('home.deleteExpense')}
                          </Button>
                        </Box>

                        <LinkButton
                          variant="outlined"
                          to={`/expenses/${row.id}`}
                        >
                          {t('home.openExpense')}
                        </LinkButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!loadingMore && !loading && hasMore && (
              <Box mt={2} display="flex" justifyContent="center">
                <Button variant="text" onClick={onLoadMore}>
                  {t('home.loadMore')}
                </Button>
              </Box>
            )}
            {loadingMore && <CircularProgress />}
          </Box>
        )}
      </Box>
      <ConfirmModal
        title={t('home.deleteExpenseConfirmationTitle')}
        open={confirmDelete}
        setOpen={setConfirmDelete}
        onConfirm={onDeleteExpense}
      >
        <Typography>
          {t('home.deleteExpenseConfirmationBody', {
            name: selectedExpense && selectedExpense.visitorEmail,
          })}
        </Typography>
      </ConfirmModal>

      <InstructionModal
        open={instructionModalOpen}
        setOpen={setInstructionModalOpen}
        onClose={onInstructionModalClosed}
        instruction={instruction}
        user={user}
        markAsRead={markAsRead}
      />
    </Box>
  );
};

export default HomePage;
