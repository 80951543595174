import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import ExpenseDetails from '../../components/expenseDetails';
import AdditionalInfoForm from '../../components/form/additionalInfo';
import useExpensesApi from '../../hooks/useExpensesApi';

const HeroBox = styled(Box)`
  background: url('/background.jpg') no-repeat center center fixed;
  background-size: cover;
  background-attachment: scroll;
  overflow: auto;
`;

const ExpenseApprove = () => {
  const history = useHistory();
  const [expense, setExpense] = useState();
  let { id } = useParams();
  const { get, loading } = useExpensesApi();

  useEffect(() => {
    const getExpense = async () => {
      const data = await get(id);
      setExpense(data);
    };
    getExpense();
  }, [id, get]);

  if (loading || !expense) {
    return (
      <Box
        display="flex"
        height="80vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width={'100%'}
    >
      <HeroBox
        display="flex"
        flex={1}
        py={[1, 2, 4]}
        px={[1, 2, 6, 12]}
      >
        <ExpenseDetails
          expense={expense}
          hideApprove
          backPath={`/expenses/${expense.id}`}
        />
      </HeroBox>
      <Box
        py={[1, 2, 4]}
        px={[1, 2, 6, 12]}
        display="flex"
        justifyContent="center"
      >
        <Box width={['100%', '600px']}>
          <Paper variant="outlined">
            <Box p={2}>
              <AdditionalInfoForm
                expense={expense}
                onSubmitted={() =>
                  history.replace({
                    pathname: `/expenses/${expense.id}`,
                  })
                }
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpenseApprove;
