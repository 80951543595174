import * as React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { default as MuiButton } from '@material-ui/core/Button';

export const Button = styled(MuiButton)``;

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const ButtonLink = styled((props) => (
  <MuiButton {...props} component={CollisionLink}>
    {props.children}
  </MuiButton>
))``;

export default ButtonLink;
