import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from './../../context/userContext';

const PrivateRoute = ({ comp: Component, ...rest }) => {
  const { user } = useContext(UserContext);

  const authFn = (props) => {
    const { location } = props;
    return user ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={authFn} />;
};

export default PrivateRoute;
