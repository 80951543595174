import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import Typography from '@material-ui/core/Typography';
import Title from '../title';
import MenuButton from '../buttons/menuButton';

import useExpensesApi from '../../hooks/useExpensesApi';

const StyledListItem = styled(ListItem)`
  flex-wrap: wrap;
`;

const Styled1ListItemText = styled(ListItemText)`
  width: 120px;
`;
const Styled2ListItemText = styled(ListItemText)`
  flex: 3;
`;

const ExpenseList = ({ expense }) => {
  const { t } = useTranslation();
  const { getAttachment } = useExpensesApi();

  const onAttachmentClick = async (filename) => {
    const data = await getAttachment(expense.id, filename);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Title text={t('expenseList.expenseRows')} />
      {expense.expenses && expense.expenses.length ? (
        <List>
          {expense.expenses.map((e, i) => (
            <StyledListItem divider key={`expense-${i}`}>
              <ListItemAvatar>
                <Avatar>{i + 1}</Avatar>
              </ListItemAvatar>
              <Styled1ListItemText
                primary={t(`expenseType.${e.type}`)}
                secondary={e.from && e.to && `${e.from} - ${e.to}`}
              />
              <Styled2ListItemText
                primary={
                  e.type === 'kilometreAllowance'
                    ? e.amount + ' Km'
                    : new Intl.NumberFormat('fi-FI', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(e.amount)
                }
                secondary={`${e.description}`}
              />
              <ListItemSecondaryAction>
                <MenuButton
                  buttonText={t('expenseList.attachmentsButton', {
                    count: e.attachments.length,
                  })}
                  menuItems={e.attachments.map((a) => a.name)}
                  onItemClick={onAttachmentClick}
                />
              </ListItemSecondaryAction>
            </StyledListItem>
          ))}
        </List>
      ) : (
        <Typography>{t('expenseList.noExpenses')}</Typography>
      )}
    </>
  );
};

export default ExpenseList;
