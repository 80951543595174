import React, { useRef } from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const SnackbarWrapper = styled(Box)`
  position: fixed;
  z-index: 1301;
  left: 5%;
  bottom: 5%;
  right: 5%;
  display: flex;
  flex-direction: column;
`;

const StyledSnackbar = styled(Snackbar)``;

class SnackbarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { snackbars: [] };
  }

  showSnackbar = (snackbar) => {
    const { snackbars } = this.state;
    if (!snackbars.find((s) => s.id === snackbar.id)) {
      this.setState({ snackbars: [...snackbars, snackbar] });
    }
  };

  closeSnackbar = (id) => {
    this.onClose(id);
  };

  onClose = (id) => {
    const { snackbars } = this.state;
    const newSnackbars = snackbars.filter((t) => t.id !== id);
    this.setState({ snackbars: newSnackbars });
  };

  render() {
    return (
      <SnackbarWrapper>
        {this.state.snackbars.map(
          ({ message, id, type, autoHideDuration }, i) => (
            <Box
              display="flex"
              justifyContent="center"
              key={id}
              mt={i ? 2 : 0}
            >
              <StyledSnackbar
                open
                autoHideDuration={autoHideDuration}
                onClose={() => this.onClose(id)}
              >
                <Alert severity={type}>{message}</Alert>
              </StyledSnackbar>
            </Box>
          ),
        )}
      </SnackbarWrapper>
    );
  }
}

const Ctx = React.createContext({
  showSnackbar: () => {
    throw new Error('showSnackbar() not implemented');
  },
  closeSnackbar: () => {
    throw new Error('closeSnackbar() not implemented');
  },
});

export function SnackbarProvider({ children }) {
  const snackbarContainer = useRef();

  const showSnackbar = (props) => {
    snackbarContainer &&
      snackbarContainer.current &&
      snackbarContainer.current.showSnackbar(props);
  };

  const closeSnackbar = (id) => {
    snackbarContainer &&
      snackbarContainer.current &&
      snackbarContainer.current.closeSnackbar(id);
  };

  return (
    <Ctx.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <SnackbarContainer ref={snackbarContainer} />
    </Ctx.Provider>
  );
}

export const useSnackbar = () => React.useContext(Ctx);
