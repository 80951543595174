import { useState, useCallback } from 'react';
import * as qs from 'qs';
import client from '../services/client';
import { useSession } from './firebaseAuth';

import { useSnackbar } from '../context/snackbarContext';

const useExpensesApi = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { user } = useSession();
  const { showSnackbar } = useSnackbar();
  const showError = useCallback(
    (err) => {
      setLoading(false);
      setError(err);
      showSnackbar({
        id: 'error' + new Date(),
        type: 'error',
        message: err.message,
      });
    },
    [showSnackbar],
  );

  const getAll = useCallback(
    async (offset, filters) => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        let params;
        if (offset) {
          params = { ...(params || {}), offset };
        }
        if (filters) {
          params = { ...(params || {}), filters };
        }
        const response = await client.get('/api/expenses', {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        });
        setLoading(false);
        return response.data;
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const getAttachment = useCallback(
    async (id, filename) => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        const response = await client.get(
          `/api/expenses/${id}/file/${filename}`,
          {
            responseType: 'arraybuffer',
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          },
        );
        setLoading(false);
        return response.data;
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const get = useCallback(
    async (id, noAuth) => {
      setError(null);
      setLoading(true);
      try {
        if (user && !noAuth) {
          const token = await user.getIdToken(false);
          const response = await client.get(`/api/expenses/${id}`, {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          });
          setLoading(false);
          return response.data;
        } else {
          const response = await client.get(
            `/api/guest/expenses/${id}`,
          );
          setLoading(false);
          return response.data;
        }
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const save = useCallback(
    async (values, noAuth) => {
      setError(null);
      setLoading(true);
      try {
        setLoading(false);
        if (user && !noAuth) {
          const token = await user.getIdToken(false);
          await client.post(`/api/expenses/${values.id}`, values, {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          });
        } else {
          await client.post(`/api/guest/expenses`, values);
        }
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const approve = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        setLoading(false);

        const token = await user.getIdToken(false);
        await client.post(
          `/api/expenses/${values.id}/approve`,
          values,
          {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          },
        );
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const deleteExpense = useCallback(
    async (id) => {
      setError(null);
      setLoading(true);
      try {
        setLoading(false);

        const token = await user.getIdToken(false);
        await client.delete(`/api/expenses/${id}`, {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        });
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const uploadAttachments = useCallback(
    async ({ id, data }, noAuth) => {
      setError(null);
      setLoading(true);
      try {
        setLoading(false);
        if (user && !noAuth) {
          const token = await user.getIdToken(false);
          await client.post(`/api/expenses/${id}/upload`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
              authorization: token ? `Bearer ${token}` : '',
            },
          });
        } else {
          await client.post(
            `/api/guest/expenses/${id}/upload`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );
        }
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  return {
    getAll,
    get,
    save,
    deleteExpense,
    approve,
    uploadAttachments,
    getAttachment,
    loading,
    error,
  };
};

export default useExpensesApi;
