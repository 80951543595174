import { useState, useCallback } from 'react';

import client from '../services/client';
import { useSession } from './firebaseAuth';

const useNotificationsApi = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { user } = useSession();

  const save = useCallback(
    async ({ email, group, visitorType, handler }) => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        const response = await client.post(
          '/api/notifications',
          {
            email,
            group,
            visitorType,
            handler
          },
          {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          },
        );
        setLoading(false);
        return response.data;
      } catch (err) {
        setLoading(false);
        setError(err);
        throw err;
      }
    },
    [user],
  );

  const handlers = useCallback(
    async () => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        const response = await client.post(
          '/api/notifications/handlers',
          {},
          {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          },
        );
        setLoading(false);
        return response.data;
      } catch (err) {
        setLoading(false);
        setError(err);
        throw err;
      }
    },
    [user],
  );

  return {
    save,
    handlers,
    loading,
    error,
  };
};

export default useNotificationsApi;
