import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const MenuButton = ({ buttonText, menuItems, onItemClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={menuItems.length === 0}
      >
        {buttonText}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id={'simple-menu'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((a, i) => (
          <MenuItem
            key={`menuitem-${i}`}
            onClick={() => {
              handleClose();
              onItemClick(typeof a === 'object' ? a.value : a);
            }}
          >
            {typeof a === 'object' ? a.label : a}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
