import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import GoogleLogo from './g-normal.png';

const GoogleButton = styled.button`
  padding: 1px;
  background-color: #4285f4;
  color: #fff;

  height: 34px;
  width: 240px;

  text-align: inherit;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  font-size: 16px;

  border-radius: 1px;
  font-family: Roboto, arial, sans-serif;
  cursor: pointer;

  :hover {
    background-color: #4285f4;
    color: #fff;
    box-shadow: rgba(66, 133, 244, 0.3) 0 0 3px 3px;
  }
`;

const GoogleLoginButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <GoogleButton
      aria-label="Google login"
      onClick={onClick}
      disabled={disabled}
      fullWidth
    >
      <Box display="flex" alignItems="center">
        <Box>
          <img alt="Google" src={GoogleLogo} />
        </Box>
        <Box flex={1} mb="5px" textAlign="center">
          {t('googleLogin')}
        </Box>
      </Box>
    </GoogleButton>
  );
};

GoogleLoginButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default GoogleLoginButton;
