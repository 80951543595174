import firebase from '../services/firebase';
import client from './client';

export const checkLogin = async (user) => {
  const token = await user.getIdToken(false);
  return client.get('/api/login', {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};

export const loginWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  if (process.env.REACT_APP_ALLOWED_DOMAIN) {
    googleProvider.setCustomParameters({
      hd: process.env.REACT_APP_ALLOWED_DOMAIN,
    });
  }

  const result = await firebase
    .auth()
    .signInWithPopup(googleProvider);

  if (result.user) {
    try {
      await checkLogin(result.user);
    } catch (err) {
      await firebase.auth().signOut();
      throw err;
    }
  } else {
    throw new Error('Not authorized');
  }
};
