import { useState, useCallback } from 'react';

import client from '../services/client';
import { useSession } from './firebaseAuth';

import { useSnackbar } from '../context/snackbarContext';

const useUsersApi = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { user } = useSession();
  const { showSnackbar } = useSnackbar();
  const showError = useCallback(
    (err) => {
      setLoading(false);
      setError(err);
      showSnackbar({
        id: 'error' + new Date(),
        type: 'error',
        message: err.message,
      });
    },
    [showSnackbar],
  );

  const getMyProfile = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const token = await user.getIdToken(false);
      const response = await client.get('/api/users/myprofile', {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      showError(err);
      throw err;
    }
  }, [showError, user]);

  return {
    getMyProfile,
    loading,
    error,
  };
};

export default useUsersApi;
