import * as React from 'react';
import * as firebase from 'firebase/app';

const userContext = React.createContext({
  user:
    firebase.auth && firebase.auth().currentUser
      ? firebase.auth().currentUser
      : null,
  signOut: () => firebase.auth && firebase.auth().signOut(),
});

export default userContext;
