import React from 'react';

import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { useSession } from '../../../hooks/firebaseAuth';

import Title from '../../title';
import useExpensesApi from '../../../hooks/useExpensesApi';

import { useSnackbar } from '../../../context/snackbarContext';

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export default function AdditionalInfoForm({ expense, onSubmitted }) {
  const { user } = useSession();

  const { t } = useTranslation();
  const { approve } = useExpensesApi();
  const { showSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      //costCenter: "",
      //projectNumber: "",
      //productNumber: "",
      approver: user.email,
      //yleContact :
    },
  });
  const { isSubmitting } = formState;

  const onSubmit = async (values) => {
    await approve({
      id: expense.id,
      ...values,
    });

    showSnackbar({
      id: 'approved',
      type: 'success',
      message: t('form.expense.saveSuccess'),
    });

    onSubmitted && onSubmitted();
  };

  return (
    <Box>
      <Title text={t('form.additionalInfo.title')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2}>
          <TextField
            name="costCenter"
            inputRef={register({
              required: t('validations.required'),
              maxLength: {
                value: 200,
                message: t('validations.maxLength', {
                  length: 200,
                }),
              },
            })}
            label={t('form.additionalInfo.costCenter')}
            fullWidth
            error={errors.costCenter ? true : false}
            helperText={
              errors.costCenter && errors.costCenter.message
            }
          />
        </Box>

        <Box mt={1}>
          <TextField
            name="projectNumber"
            inputRef={register({
              maxLength: {
                value: 200,
                message: t('validations.maxLength', {
                  length: 200,
                }),
              },
            })}
            label={t('form.additionalInfo.projectNumber')}
            fullWidth
            error={errors.projectNumber ? true : false}
            helperText={
              errors.projectNumber && errors.projectNumber.message
            }
          />
        </Box>

        <Box mt={1}>
          <TextField
            name="productNumber"
            inputRef={register({
              maxLength: {
                value: 200,
                message: t('validations.maxLength', {
                  length: 200,
                }),
              },
            })}
            label={t('form.additionalInfo.productNumber')}
            fullWidth
            error={errors.productNumber ? true : false}
            helperText={
              errors.productNumber && errors.productNumber.message
            }
          />
        </Box>

        <Box mt={1}>
          <TextField
            name="approver"
            inputRef={register({
              required: t('validations.required'),
              maxLength: {
                value: 200,
                message: t('validations.maxLength', {
                  length: 200,
                }),
              },
            })}
            label={t('form.additionalInfo.approver')}
            fullWidth
            error={errors.approver ? true : false}
            helperText={errors.approver && errors.approver.message}
          />
        </Box>

        <Box mt={1}>
          <TextField
            name="yleContactInfo"
            inputRef={register({
              required: t('validations.required'),
              maxLength: {
                value: 200,
                message: t('validations.maxLength', {
                  length: 200,
                }),
              },
            })}
            label={t('form.additionalInfo.yleContactInfo')}
            fullWidth
            error={errors.yleContactInfo ? true : false}
            helperText={
              errors.yleContactInfo && errors.yleContactInfo.message
            }
          />
        </Box>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Box position="relative">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {t('form.additionalInfo.submitAdditionalInfo')}
            </Button>
            {isSubmitting && <StyledCircularProgress size={24} />}
          </Box>
        </Box>
      </form>
    </Box>
  );
}
