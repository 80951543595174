import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-image: linear-gradient(
      to right,
      transparent 98%,
      #335dbd26 100%
    ),
    url(${(props) => props.url});
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 0px 3px 3px 0px;
  filter: brightness(90%);
`;
