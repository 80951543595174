import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const ReminderBox = styled(Box)`
  border: solid 3px #ff5733;
  border-radius: 4px;
`;

const ReminderText = styled(Typography)`
  font-size: 24pt;
  color: #ff5733;
`;

const TestingReminder = () => {
  return (
    <Box mb={1} mr={2}>
      <Paper>
        <ReminderBox p={5}>
          <ReminderText>TESTIYMPÄRISTÖ</ReminderText>
        </ReminderBox>
      </Paper>
    </Box>
  );
};

export default TestingReminder;
