import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { Background as StyledBackground } from '../../components/styled/background';
import { Logo as StyledLogo } from '../../components/styled/logo';

import GoogleLoginButton from '../../components/buttons/googleLoginButton';

import { loginWithGoogle } from '../../services/googleLogin';

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;
const FullHeightGridHideXs = styled(FullHeightGrid)`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const LoginGrid = styled(Grid)`
  background-color: #f7f7f7;
  height: 100%;
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onGoogleButtonClick = async () => {
    setError();
    setLoading(true);
    try {
      await loginWithGoogle();
      setLoading(false);
      if (location && location.state && location.state.from.pathname)
        history.replace({ pathname: location.state.from.pathname });
      else history.replace('/');
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError('Error in signing in.');
    }
  };

  return (
    <FullHeightGrid container>
      <FullHeightGridHideXs item xs={12} sm={6}>
        <StyledBackground url="/background.jpg" />
      </FullHeightGridHideXs>
      <FullHeightGrid item xs={12} sm={6}>
        <LoginGrid
          container
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid itemProp="true">
            <Box display="flex" alignItems="center" mb={5}>
              <StyledLogo url="/yle-logo.svg" />
              <Box ml={2}>
                <Typography variant="h6">{t('appName')}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            {!loading && (
              <GoogleLoginButton onClick={onGoogleButtonClick} />
            )}
            {error && <Typography color="error">{error}</Typography>}
          </Grid>
        </LoginGrid>
      </FullHeightGrid>
    </FullHeightGrid>
  );
};

export default LoginPage;
