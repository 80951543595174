import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Text = styled(Typography)`
  white-space: pre-wrap;
`;

const InstructionModal = (props) => {
  const {
    open,
    setOpen,
    onClose,
    user,
    instruction,
    markAsRead,
  } = props;
  const [text, setText] = useState(
    (instruction && instruction.text) || '',
  );
  const { t } = useTranslation();
  const admin = user && user.role === 'admin';

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (instruction) {
      setText(instruction.text);
      if (!instruction.read && open) {
        markAsRead();
      }
    }
  }, [instruction, open, markAsRead]);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="instructions-dialog"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="instructions-dialog">
        {admin
          ? t('instructionModal.titleAdmin')
          : t('instructionModal.title')}
      </DialogTitle>
      <DialogContent>
        {admin ? (
          <TextField
            inputProps={{
              maxLength: 10000,
            }}
            variant="outlined"
            label={t('instructionModal.textField')}
            placeholder={t('instructionModal.textFieldPlaceholder')}
            multiline
            rows={10}
            rowsMax={30}
            fullWidth
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        ) : (
          <Box minHeight="200px">
            <Text>{instruction && instruction.text}</Text>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {admin && (
          <Button variant="text" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            onClose(text);
          }}
          color="default"
        >
          {admin ? t('save') : t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InstructionModal;
