import React, { useState } from 'react';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { sanitizeName } from '../../../utils/utils';

const HiddenFileInput = styled.input`
  display: none;
`;

const FileInput = ({ name, value, error, helperText, onChange }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState(value);

  const [fileError, setFileError] = useState([]);

  const onFileChange = (e) => {
    setFileError(null);
    let file = null;
    const newFiles = [...files];
    for (let index = 0; index < e.target.files.length; index++) {
      let size = e.target.files[index].size / 1024 / 1024;
      size = size.toFixed(2);
      if (size > 4) {
        setFileError(
          t('validations.fileTooLarge', {
            name: e.target.files[index].name,
          }),
        );
      } else if (newFiles.name !== e.target.files[index].name)
        file = new File([e.target.files[index]], sanitizeName(e.target.files[index].name));
        newFiles.push(file);
        console.log(newFiles);
    }
    setFiles(newFiles);
    onChange(newFiles);
  };

  const onDelete = (e, file) => {
    setFileError(null);
    e.stopPropagation();
    const newFiles = files.filter((item) => item !== file);
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <Box>
      <Box display="flex" flexWrap="wrap">
        {(files || []).map((file, i) => (
          <Box mr={1} mb={1} key={`attachment-${i}`}>
            <Chip
              label={file.name}
              onDelete={(e) => onDelete(e, file)}
              color="primary"
            />
          </Box>
        ))}
        {fileError && (
          <Box mb={1} mr={2}>
            <Typography color="error">{fileError}</Typography>
          </Box>
        )}
        {error && (
          <Box mb={1}>
            <Typography color="error">{helperText}</Typography>
          </Box>
        )}
      </Box>
      <Box display="flex">
        <HiddenFileInput
          name={name}
          id={name + '-file'}
          multiple
          type="file"
          accept="image/*, application/pdf"
          onChange={onFileChange}
        />
        <label htmlFor={name + '-file'}>
          <Button
            variant="contained"
            color="primary"
            component="span"
          >
            {t('form.expense.addAttachment')}
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default FileInput;
