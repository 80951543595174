import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import Title from '../title';
import ExpenseState from '../expenseState';
import LinkButton from '../buttons/linkButton';
import {
  getGroup,
  getTotalAmount,
  getTotalDistance,
} from '../../utils/utils';

const BoldText = styled(Typography)`
  font-weight: bold;
`;

const LightText = styled(Typography)`
  font-weight: lighter;
  white-space: pre-wrap;
`;

const DetailItem = ({ title, value }) => (
  <Box display="flex" flexWrap="wrap">
    <Box width="200px">
      <BoldText>{title}</BoldText>
    </Box>
    <LightText>{value}</LightText>
  </Box>
);
const StyledPaper = styled(Paper)`
  width: 100%;
`;

const ExpenseDetails = ({ expense, hideApprove, backPath }) => {
  const { t, i18n } = useTranslation();

  let title = '';
  let subtitle;
  if (expense.name) {
    title = `${expense.name} - ${expense.handler || getGroup(expense.group)}`;
    subtitle = expense.visitorEmail;
  } else
    title = `${expense.visitorEmail} - ${expense.handler || getGroup(expense.group)}`;

  let totalAmount = getTotalAmount(expense);
  let totalDistance = getTotalDistance(expense);
  return (
    <StyledPaper variant="outlined">
      <Box p={[1, 2, 4]}>
        <Box>
          <LinkButton variant="text" to={backPath}>
            <ChevronLeft />
            {t('goBack')}
          </LinkButton>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box mb={[2, 0]}>
            <Title text={title} />

            <Box pt={1} display="flex" flexDirection="column">
              {subtitle && (
                <DetailItem
                  title={t('expenseDetails.visitorEmail')}
                  value={subtitle}
                />
              )}

              <DetailItem
                title={t('expenseDetails.visitTime')}
                value={
                  expense.startTime &&
                  `${new Date(expense.startTime).toLocaleDateString(
                    i18n.language,
                  )}`
                }
              />
              <DetailItem
                title={t('expenseDetails.iban')}
                value={expense.iban}
              />
              <DetailItem
                title={t('expenseDetails.address')}
                value={`${expense.address}\r\n${expense.zip} ${expense.city}`}
              />
              <DetailItem
                title={t('expenseDetails.phone')}
                value={expense.phone}
              />
              <DetailItem
                title={t('expenseDetails.ssn')}
                value={expense.ssn}
              />
              <DetailItem
                title={t('expenseDetails.reason')}
                value={expense.reason}
              />
            </Box>
          </Box>
          <Box width={['100%', 'auto']}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Typography color="primary">
                {t('expenseDetails.totalAmount')}
              </Typography>
              <Typography variant="h4" color="primary">
                {totalAmount}
              </Typography>

              {(expense.expenses || []).filter(
                (e) => e.type === 'kilometreAllowance',
              ).length > 0 && (
                <>
                  <Typography color="primary">
                    {t('expenseDetails.totalDistance')}
                  </Typography>
                  <Typography variant="h5" color="primary">
                    {totalDistance}
                  </Typography>
                </>
              )}
              <Box pt={1}>
                <ExpenseState state={expense.state} />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={'100%'}
          >
            <LinkButton
              disabled={expense.state === 'approved'}
              variant="contained"
              to={`/expenses/${expense.id}/edit`}
            >
              {t('expenseDetails.edit')}
            </LinkButton>
            {!hideApprove && (
              <Box ml={2}>
                <LinkButton
                  disabled={
                    expense.state === 'approved' ||
                    expense.state === 'sent'
                  }
                  variant="contained"
                  color="primary"
                  to={`/expenses/${expense.id}/approve`}
                >
                  {t('expenseDetails.accept')}
                </LinkButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default ExpenseDetails;
