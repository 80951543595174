import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
`;

const StateText = styled(Typography)`
  text-transform: uppercase;
`;

const ExpenseState = ({ state, date }) => {
  const { t } = useTranslation();

  if (state === 'approved')
    return (
      <Box display="flex" alignItems="center">
        <Circle bgColor="#78B928" />
        <Box ml={1}>
          <StateText variant="body2">{t('state.approved')}</StateText>
        </Box>
      </Box>
    );

  if (state === 'pending')
    return (
      <Box display="flex" alignItems="center">
        <Circle bgColor="#FD7301" />
        <Box ml={1}>
          <StateText variant="body2">{t('state.pending')}</StateText>
        </Box>
      </Box>
    );

  if (state === 'sent')
    return (
      <Box display="flex" alignItems="center">
        <Circle bgColor="#EDEDED" />
        <Box ml={1}>
          <StateText variant="body2">{t('state.sent')}</StateText>
        </Box>
      </Box>
    );

  return null;
};

export default ExpenseState;
