import { useContext, useEffect, useState } from 'react';
import '../services/firebase';
import UserContext from '../context/userContext';

const useSession = () => {
  const { user, signOut } = useContext(UserContext);
  return { user, signOut };
};

const useAuth = (auth) => {
  const [state, setState] = useState(() => {
    const user = auth.currentUser;
    return { loading: !user, user };
  });

  const onChange = async (user) => {
    if (user) {
      setState({ loading: false, user, error: undefined });
    } else {
      setState({ loading: false, user, error: undefined });
    }
  };

  function onError(error) {
    setState({ loading: false, error, user: undefined });
  }

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(onChange, onError);
    return () => unsubscribe();
  }, [auth]);

  const { loading, error, user, roles } = state;
  return [user, loading, roles || [], error];
};

export { useSession, useAuth };
