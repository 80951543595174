import React from 'react';
import styled from 'styled-components';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';

import { useSession } from '../../../hooks/firebaseAuth';

import MenuButton from '../../buttons/menuButton';

import Fi from './fi';
import En from './en';
import Sv from './sv';

const langs = {
  en: () => <En />,
  fi: () => <Fi />,
  sv: () => <Sv />,
};

const StyledAppBar = styled(AppBar)`
  background-color: #fff;
`;

const BigAvatar = styled(Avatar)`
  ${({ theme }) => `
  
  width: ${theme.spacing(7)}px;
  height: ${theme.spacing(7)}px;
  `}
`;

const EmailTypography = styled(Typography)`
  color: #b1b1b1;
`;

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { user, signOut } = useSession();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box display="flex">
      <StyledAppBar position="static">
        <Toolbar>
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <img
                  width="42px"
                  height="42px"
                  src={'/yle-logo.svg'}
                  alt="Yle"
                />
              </Box>
              <Box mt="-5px">
                <Typography color="textPrimary" variant="h6" noWrap>
                  {t('appName')}
                </Typography>
              </Box>
            </Box>
              <Box alignItems="center" flexDirection="column">
                <Box>
                <MenuButton
                    buttonText={langs[i18n.language.split('-')[0]]()}
                    menuItems={[
                      {
                        value: 'fi',
                        label: <Fi />,
                      },
                      { value: 'sv', label: <Sv /> },
                      {
                        value: 'en',
                        label: <En />,
                      },
                    ]}
                    onItemClick={(l) => i18n.changeLanguage(l)}
                  />
                  {user &&
                    <IconButton
                      aria-describedby={id}
                      onClick={handleClick}
                    >
                      <Avatar src={user.photoURL} alt={user.email} />
                    </IconButton>    
                  }  
                </Box>
              </Box>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box px={5} p={3}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {user && (
              <>
                <BigAvatar src={user.photoURL} alt={user.email} />
                <Box mt={1}>
                  <Typography variant="h6">
                    {user.displayName}
                  </Typography>
                </Box>
                <Box>
                  <EmailTypography variant="subtitle2">
                    {user.email}
                  </EmailTypography>
                </Box>
              </>
            )}

            <Box mt={3}>
              <Button variant="outlined" onClick={signOut}>
                {t('signout')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Navbar;
