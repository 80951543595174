import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  min-width: 150px;
`;

const Dropdown = ({
  label = '',
  id,
  fields = [],
  onChange = () => '',
}) => {
  return (
    <StyledTextField
      select
      hiddenLabel
      multiline
      maxRows={4}
      id={id}
      label={label}
      size="small"
      onChange={(event) => onChange(event.target.value)}
    >
      {fields.map((field) => (
        <MenuItem key={field.value} value={field.value}>
          {field.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};
export default Dropdown;
