import { useState, useCallback } from 'react';

import client from '../services/client';
import { useSession } from './firebaseAuth';

import { useSnackbar } from '../context/snackbarContext';

const useInstructionsApi = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { user } = useSession();
  const { showSnackbar } = useSnackbar();
  const showError = useCallback(
    (err) => {
      setLoading(false);
      setError(err);
      showSnackbar({
        id: 'error' + new Date(),
        type: 'error',
        message: err.message,
      });
    },
    [showSnackbar],
  );

  const get = useCallback(
    async (offset) => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        const response = await client.get('/api/instructions', {
          params: offset
            ? {
                offset,
              }
            : undefined,
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        });
        setLoading(false);
        return response.data;
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const save = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        const token = await user.getIdToken(false);
        const response = await client.post(
          `/api/instructions`,
          values,
          {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          },
        );
        setLoading(false);
        return response.data;
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [showError, user],
  );

  const markAsRead = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      setLoading(false);
      const token = await user.getIdToken(false);
      await client.post(
        `/api/instructions/read`,
        {},
        {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        },
      );
    } catch (err) {
      showError(err);
      throw err;
    }
  }, [showError, user]);

  return {
    get,
    save,
    markAsRead,
    loading,
    error,
  };
};

export default useInstructionsApi;
