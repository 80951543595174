import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import FormExpense from '../../components/form/expense';
import useExpensesApi from '../../hooks/useExpensesApi';

const UserPage = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const { get, error, loading } = useExpensesApi();
  const [expense, setExpense] = useState();
  const [submitted, setSubmitted] = useState();

  useEffect(() => {
    async function validate() {
      const data = await get(id, true);
      setExpense(data);
    }
    validate();
  }, [id, get]);

  if (error) {
    return (
      <Box display="flex">
        <Typography>{t('user.invalidExpense')}</Typography>
      </Box>
    );
  }
  if (loading || !expense) {
    return (
      <Box
        display="flex"
        height="80vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt={5} px={2}>
      {submitted ? (
        <Box display="flex" justifyContent="center">
          <Box width={['100%', '600px']}>
            <Paper variant="outlined">
              <Box p={2}>
                <Typography color="primary">
                  {t('user.expenseSubmitted')}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <FormExpense
          guest
          expense={expense}
          onSubmitted={() => setSubmitted(true)}
        />
      )}
    </Box>
  );
};

export default UserPage;
