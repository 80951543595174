import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';

import { useForm, Controller } from 'react-hook-form';

import { useSnackbar } from '../../../context/snackbarContext';
import { groups, visitorTypes, specialGroup } from '../../../utils/utils';
import useNotificationsApi from '../../../hooks/useNotificationsApi';

const options = [...groups];
options.push(specialGroup);

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const HidableDiv = styled.div`
  ${(props) => props.hidden ? 'display: hidden;' : ''}
`;

export default function VisitorExpenseNotificationForm({
  onSubmitted,
}) {
  const { showSnackbar } = useSnackbar();
  const { save, handlers } = useNotificationsApi();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState,
    reset,
    watch,
    clearErrors,
    setValue
  } = useForm({
    defaultValues: {},
  });
  const { isSubmitting } = formState;

  const [localHandlers, setLocalHandlers] = useState([]);
  const [localHandler, setLocalHandler] = useState(null);

  const groupValue = watch('group');

  const onSubmit = async (values) => {
    const finalValues = {...values};
    finalValues.handler = localHandler ? localHandler : null;
    finalValues.group = values.group === specialGroup.value ? null : values.group;
    await save(finalValues);

    reset({});
    setLocalHandler(null);

    showSnackbar({
      id: 'sent',
      type: 'success',
      message: t('form.visitorExpenseNotification.saveSuccess'),
    });

    onSubmitted();
  };

  const isHidden = groupValue !== specialGroup.value;

  useEffect(() => {
    if (groupValue !== specialGroup.value) {
      clearErrors('handler');
      setLocalHandler(null);
      setValue('handler', '');
    }
  }, [groupValue, clearErrors, setValue]);

  useEffect(() => {
    const getHandlers = async () => {
      const resp = await handlers();
      setLocalHandlers(resp);
    }
    getHandlers();
  }, [handlers]);

  const handlerChange = (value) => {
    if (value && value.length && value.includes('(')) {
      const regExp = /\(([^)]+)\)/;
      const match = regExp.exec(value);
      setLocalHandler(match[1]);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        flexDirection="column"
        mt={2}
        width={['100%', '400px']}
      >
        <Box mt={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="email"
            inputRef={register({
              required: t('validations.required'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('validations.invalidEmail'),
              },
            })}
            label={t('form.visitorExpenseNotification.visitorEmail')}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
          />
        </Box>

        <Box mt={3}>
          <Controller
            as={
              <TextField
                fullWidth
                variant="outlined"
                select
                label={t(
                  'form.visitorExpenseNotification.visitorType',
                )}
                error={errors.visitorType ? true : false}
                helperText={
                  errors.visitorType && errors.visitorType.message
                }
              >
                {visitorTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(`visitorType.${option.value}`)}
                  </MenuItem>
                ))}
              </TextField>
            }
            name="visitorType"
            rules={{ required: t('validations.required') }}
            control={control}
            defaultValue={''}
          />
        </Box>

        <Box mt={3}>
          <Controller
            as={
              <TextField
                fullWidth
                variant="outlined"
                select
                label={t('form.visitorExpenseNotification.yleGroup')}
                error={errors.group ? true : false}
                helperText={errors.group && errors.group.message}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${option.label}${option.value !== specialGroup.value ? ` (${option.value})`: ''}`}
                  </MenuItem>
                ))}
              </TextField>
            }
            name="group"
            rules={{ required: t('validations.required') }}
            control={control}
            defaultValue=""
          />
        </Box>

        <HidableDiv hidden={isHidden}>
          <Box mt={3}>
            <Controller
              as={
                <Autocomplete
                  options={localHandlers}
                  getOptionLabel={option => option ? `${option.name} (${option.email})` : ''}
                  onInputChange={(e, val) => handlerChange(val)}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label={t('form.visitorExpenseNotification.handler')}
                      fullWidth
                      variant="outlined"
                      error={errors.handler ? true : false}
                      helperText={errors.handler && errors.handler.message}
                    />
                  }
                />
              }
              name="handler"
              rules={{ required: isHidden ? false : t('validations.required') }}
              control={control}
            />
          </Box>
        </HidableDiv>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Box position="relative">
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {t(
                'form.visitorExpenseNotification.submitVisitorNotification',
              )}
            </Button>
            {isSubmitting && <StyledCircularProgress size={24} />}
          </Box>
        </Box>
      </Box>
    </form>
  );
}
