import React from 'react';

import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

import DateFnsUtils from '@date-io/date-fns';
import IBAN from 'iban';
import { FinnishSSN } from 'finnish-ssn';
import { useTranslation } from 'react-i18next';

import FileInput from './fileinput';

import Title from '../../title/title';
import { useSnackbar } from '../../../context/snackbarContext';
import { useSession } from '../../../hooks/firebaseAuth';
import useExpensesApi from '../../../hooks/useExpensesApi';

const expenseTypes = [
  { value: 'train' },
  { value: 'taxi' },
  { value: 'bus' },
  { value: 'kilometreAllowance' },
  { value: 'accommodation' },
  { value: 'other' },
];

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const renameFile = (filenames, currentFilename, index) => {
  const filenameSplitted = currentFilename.split('.');
  const newFilename =
    filenameSplitted.length > 1
      ? `${filenameSplitted[0]}(${index}).${filenameSplitted[1]}`
      : `${filenameSplitted[0]}(${index})`;
  if (filenames[newFilename]) {
    return renameFile(filenames, currentFilename, index + 1);
  }
  return newFilename;
};

export default function ExpenseFrom({ expense, onSubmitted, guest }) {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { user } = useSession();

  const { uploadAttachments, save } = useExpensesApi();

  const editing = user && expense && !guest;
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState,
    watch,
  } = useForm({
    defaultValues: editing
      ? { ...expense }
      : {
          id: expense.id,
          startTime: new Date(),
          visitorEmail: expense.visitorEmail,
          expenses: [
            {
              date: new Date(),
              type: expenseTypes[0].value,
              attachments: [],
            },
          ],
        },
  });

  const { isSubmitting } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'expenses',
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    const attachmentsNames = {};

    values.expenses = (values.expenses || []).map((e) => {
      e.attachments = (e.attachments || []).map((a) => {
        let filename = a.name;
        if (attachmentsNames[filename]) {
          filename = renameFile(attachmentsNames, filename, 0);
        }
        attachmentsNames[filename] = a;
        if (a.constructor === File) formData.append(filename, a);
        return { name: filename };
      });
      e.amount = parseFloat(e.amount);
      return e;
    });

    let entries = 0;
    /* eslint-disable no-unused-vars */
    for (const p of formData.entries()) {
      entries++;
    }
    /* eslint-enable no-unused-vars */
    if (entries) {
      await uploadAttachments(
        { id: expense.id, data: formData },
        guest,
      );
    }

    await save(
      {
        id: expense.id,
        ...values,
      },
      guest,
    );

    showSnackbar({
      id: 'sent',
      type: 'success',
      message: t('form.expense.saveSuccess'),
    });

    onSubmitted && onSubmitted();
  };

  const expenses = fields.length ? watch('expenses', fields) : [];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={8}>
          <Title
            text={
              editing
                ? t('form.expense.editTitle', { id: expense.id })
                : t('form.expense.title')
            }
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection={['column', 'row']}
              mt={2}
            >
              <Box flex={4} mr={[0, 2]}>
                <TextField
                  name="name"
                  inputRef={register({
                    required: t('validations.required'),
                    maxLength: {
                      value: 200,
                      message: t('validations.maxLength', {
                        length: 200,
                      }),
                    },
                  })}
                  label={t('form.expense.visitorName')}
                  fullWidth
                  error={errors.name ? true : false}
                  helperText={errors.name && errors.name.message}
                />
              </Box>
              <Box flex={3}>
                <TextField
                  name="ssn"
                  inputRef={register({
                    required: t('validations.required'),
                    validate: (value) =>
                      FinnishSSN.validate(value) ||
                      t('validations.invalidSSN'),
                  })}
                  label={t('form.expense.ssn')}
                  fullWidth
                  error={errors.ssn ? true : false}
                  helperText={errors.ssn && errors.ssn.message}
                />
              </Box>
            </Box>

            <Box mt={1}>
              <TextField
                name="visitorEmail"
                inputRef={register({
                  required: t('validations.required'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validations.invalidEmail'),
                  },
                })}
                label={t('form.expense.email')}
                fullWidth
                error={errors.visitorEmail ? true : false}
                helperText={
                  errors.visitorEmail && errors.visitorEmail.message
                }
              />
            </Box>

            <Box mt={1}>
              <TextField
                name="address"
                inputRef={register({
                  required: t('validations.required'),
                })}
                label={t('form.expense.address')}
                fullWidth
                error={errors.address ? true : false}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={['column', 'row']}
              mt={1}
            >
              <Box maxWidth="400px">
                <TextField
                  name="zip"
                  inputRef={register({
                    required: t('validations.required'),
                  })}
                  label={t('form.expense.zip')}
                  fullWidth
                  error={errors.zip ? true : false}
                />
              </Box>
              <Box flex={1} ml={[0, 2]}>
                <TextField
                  name="city"
                  inputRef={register({
                    required: t('validations.required'),
                  })}
                  label={t('form.expense.city')}
                  fullWidth
                  error={errors.city ? true : false}
                />
              </Box>
            </Box>

            <Box mt={1}>
              <TextField
                name={`phone`}
                type="tel"
                autoComplete="tel"
                inputRef={register({
                  maxLength: {
                    value: 200,
                    message: t('validations.maxLength', {
                      length: 200,
                    }),
                  },
                })}
                label={t('form.expense.phone')}
                fullWidth
                error={errors.phone ? true : false}
                helperText={errors.phone && errors.phone.message}
              />
            </Box>

            <Box mt={1}>
              <TextField
                name="iban"
                inputRef={register({
                  required: t('validations.required'),
                  validate: (value) =>
                    IBAN.isValid(value) ||
                    t('validations.invalidIBAN'),
                })}
                label={t('form.expense.iban')}
                fullWidth
                error={errors.iban ? true : false}
                helperText={errors.iban && errors.iban.message}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={['column', 'row']}
              maxWidth="300px"
              mt={2}
            >
              <Controller
                as={
                  <KeyboardDatePicker
                    clearable
                    disableFuture
                    format="dd.MM.yyyy"
                    label={t('form.expense.visitTime')}
                    fullWidth
                    error={errors.startTime ? true : false}
                  />
                }
                name="startTime"
                rules={{ required: true }}
                control={control}
              />
            </Box>
            <Box mt={1}>
              <TextField
                name={`reason`}
                inputRef={register({
                  required: t('validations.required'),
                  maxLength: {
                    value: 2000,
                    message: t('validations.maxLength', {
                      length: 2000,
                    }),
                  },
                })}
                multiline={true}
                label={t('form.expense.reason')}
                fullWidth
                error={errors.reason ? true : false}
                helperText={errors.reason && errors.reason.message}
              />
            </Box>

            <Box mt={3}>
              <Box mb={2}>
                <Typography variant="h6">
                  {t('form.expense.expenses')}
                </Typography>
              </Box>
              {fields.length === 0 && (
                <Typography>
                  {t('form.expense.noExpenses')}
                </Typography>
              )}
              {fields.length > 0 &&
                fields.map((item, index) => (
                  <Box
                    key={item.id}
                    p={2}
                    bgcolor={`${
                      index % 2 === 0 ? '#eaeaea' : '#f9f9f9'
                    }`}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography>
                        {t('form.expense.expense', {
                          index: index + 1,
                        })}
                      </Typography>
                      <Button
                        variant="contained"
                        disabled={fields.length === 1}
                        size="small"
                        color="secondary"
                        onClick={() => remove(index)}
                      >
                        {t('form.expense.removeExpense')}
                      </Button>
                    </Box>
                    <Box
                      mt={2}
                      px={[0, 2]}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        display="flex"
                        flexDirection={['column', 'row']}
                      >
                        <Box mr={[0, 2]} flex={1}>
                          <Controller
                            as={
                              <KeyboardDatePicker
                                clearable
                                format="dd.MM.yyyy"
                                ampm={false}
                                label={t('form.expense.expenseDate')}
                                fullWidth
                              />
                            }
                            name={`expenses[${index}].date`}
                            rules={{
                              required: t('validations.required'),
                            }}
                            control={control}
                            defaultValue={item.date}
                          />
                          {errors.expenses &&
                            errors.expenses[index] &&
                            errors.expenses[index].date && (
                              <FormLabel error>
                                {errors.expenses[index].date.message}
                              </FormLabel>
                            )}
                        </Box>
                        <Box flex={1}>
                          <FormControl fullWidth>
                            <InputLabel>
                              {t('form.expense.type')}
                            </InputLabel>
                            <Controller
                              as={
                                <Select>
                                  {expenseTypes.map((item) => (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {t(`expenseType.${item.value}`)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              }
                              name={`expenses[${index}].type`}
                              rules={{
                                required: t('validations.required'),
                              }}
                              control={control}
                              defaultValue={item.type}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        mt={1}
                        display="flex"
                        flexDirection={['column', 'row']}
                      >
                        <Box mr={[0, 2]} flex={1}>
                          <TextField
                            name={`expenses[${index}].from`}
                            inputRef={register()}
                            label={t('form.expense.from')}
                            fullWidth
                            defaultValue={item.from}
                          />
                        </Box>
                        <Box flex={1}>
                          <TextField
                            name={`expenses[${index}].to`}
                            inputRef={register()}
                            label={t('form.expense.to')}
                            fullWidth
                            defaultValue={item.to}
                          />
                        </Box>
                      </Box>

                      <Box mt={1}>
                        <TextField
                          name={`expenses[${index}].description`}
                          inputRef={register()}
                          multiline={true}
                          label={t('form.expense.description')}
                          fullWidth
                          defaultValue={item.description}
                        />
                      </Box>

                      <Box
                        mt={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Box width={150}>
                          <TextField
                            defaultValue={item.amount}
                            name={`expenses[${index}].amount`}
                            inputRef={register({
                              required: t('validations.required'),
                              min: 0,
                            })}
                            type="number"
                            label={
                              expenses[index] &&
                              expenses[index].type ===
                                'kilometreAllowance'
                                ? t('form.expense.distance')
                                : t('form.expense.amount')
                            }
                            inputProps={{ min: '0', step: 'any' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {expenses[index] &&
                                  expenses[index].type ===
                                    'kilometreAllowance'
                                    ? 'km'
                                    : '€'}
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            error={
                              errors.expenses &&
                              errors.expenses[index] &&
                              errors.expenses[index].amount
                                ? true
                                : false
                            }
                            helperText={
                              errors.expenses &&
                              errors.expenses[index] &&
                              errors.expenses[index].amount &&
                              errors.expenses[index].amount.message
                            }
                          />
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Controller
                          control={control}
                          name={`expenses[${index}].attachments`}
                          defaultValue={item.attachments}
                          render={({ onChange, value }) => (
                            <FileInput
                              name={`expenses[${index}].attachments`}
                              value={value}
                              onChange={onChange}
                              error={
                                errors.expenses &&
                                errors.expenses[index] &&
                                errors.expenses[index].attachments
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.expenses &&
                                errors.expenses[index] &&
                                errors.expenses[index].attachments &&
                                errors.expenses[index].attachments
                                  .message
                              }
                            />
                          )}
                          rules={{
                            validate: (v) => {
                              return expenses[index] &&
                                expenses[index].type ===
                                  'kilometreAllowance'
                                ? true
                                : (v && v.length > 0) ||
                                    t('validations.required');
                            },
                          }}
                        />

                        {/**/}
                      </Box>
                    </Box>
                  </Box>
                ))}
              <Box mt={1} display="flex">
                <Button
                  variant="contained"
                  onClick={() =>
                    append({
                      date: new Date(),
                      type: expenseTypes[0].value,
                      attachments: [],
                    })
                  }
                >
                  {t('form.expense.newExpense')}
                </Button>
              </Box>
            </Box>
            <Box
              mt={3}
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
            >
              <Box mb={1}>
                <Link
                  href={t('form.expense.termsPdfUrl')}
                  target="_blank"
                >
                  <Typography>
                    {t('form.expense.termsLink')}
                  </Typography>
                </Link>
              </Box>
              <Box position="relative">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {editing
                    ? t('form.expense.save')
                    : t('form.expense.submitExpenses')}
                </Button>
                {isSubmitting && <StyledCircularProgress size={24} />}
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
