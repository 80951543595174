import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';

const debounced = debounce((previousValue, newValue, callback) => {
  //Try to minimize the amount of useless callbacks
  //TODO: Check if we need to stop requests with low amount of characters (e.g. newValuer.length > 1)
  if (newValue === '' && previousValue === '') {
    return;
  }

  callback(newValue);
}, 1500);

const Search = ({ label = '', id, onChange = () => '' }) => {
  const [previousValue, setValue] = useState('');
  //Otherwise useless function but we need to be able to check the previous value of the TextField
  const afterDebounce = (value) => {
    onChange(value);
    setValue(value);
  };
  return (
    <TextField
      hiddenLabel
      id={id}
      label={label}
      size="small"
      onChange={(event) =>
        debounced(previousValue, event.target.value, afterDebounce)
      }
    />
  );
};
export default Search;
