import styled from 'styled-components';

export const Logo = styled.div`
  background-image: url(${(props) => props.url});
  background-size: 80px 80px;
  width: 80px;
  height: 80px;

  background-repeat: no-repeat;
  background-position: center center;
`;
