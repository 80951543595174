import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpenseDetails from '../../components/expenseDetails';
import ExpenseList from '../../components/expenseList';
import useExpensesApi from '../../hooks/useExpensesApi';

const HeroBox = styled(Box)`
  background: url('/background.jpg') no-repeat center center fixed;
  background-size: cover;
  background-attachment: scroll;
  overflow: auto;
`;

const Expense = () => {
  const [expense, setExpense] = useState();
  let { id } = useParams();
  const { get, loading } = useExpensesApi();

  useEffect(() => {
    const getExpense = async () => {
      const data = await get(id);
      setExpense(data);
    };
    getExpense();
  }, [id, get]);

  if (loading || !expense) {
    return (
      <Box
        display="flex"
        height="80vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width={'100%'}
    >
      <HeroBox
        display="flex"
        flex={1}
        py={[1, 2, 4]}
        px={[1, 2, 6, 12]}
      >
        <ExpenseDetails expense={expense} backPath="/" />
      </HeroBox>
      <Box
        py={[1, 2, 4]}
        px={[1, 2, 6, 12]}
        display="flex"
        justifyContent="center"
      >
        <Box py={2} px={[1, 2, 4]} width={['100%']}>
          <ExpenseList expense={expense} />
        </Box>
      </Box>
    </Box>
  );
};

export default Expense;
