import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ExpenseForm from '../../components/form/expense';
import useExpensesApi from '../../hooks/useExpensesApi';

const ExpenseEdit = () => {
  const [expense, setExpense] = useState();
  let { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { get, loading } = useExpensesApi();

  useEffect(() => {
    const getExpense = async () => {
      const data = await get(id);
      setExpense(data);
    };
    getExpense();
  }, [id, get]);

  if (loading || !expense) {
    return (
      <Box
        display="flex"
        height="80vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width={'100%'}
    >
      <Box>
        <Button variant="text" onClick={history.goBack}>
          <ChevronLeft />
          {t('goBack')}
        </Button>
        <Box display="flex" justifyContent="center">
          <Box p={5} px={[2, 2, 10]} width={['100%']}>
            <ExpenseForm expense={expense} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpenseEdit;
