import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Fi = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Box mr={1}>
        <img
          src="https://flagcdn.com/16x12/fi.png"
          srcset="https://flagcdn.com/32x24/fi.png 2x,
https://flagcdn.com/48x36/fi.png 3x"
          width="16"
          height="12"
          alt="Suomi"
        />
      </Box>
      <Typography>{t(`language.fi`)}</Typography>
    </Box>
  );
};

export default Fi;
