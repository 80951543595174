import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  vertical-align: top;
`;
const TextCell = styled(TableCell)`
  vertical-align: middle;
  padding-right: 0;
  padding-left: 0;
`;
const InputCell = styled(TableCell)`
  min-width: 50px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
`;

const ColumnHeadCell = ({ children }) => {
  return (
    <StyledTableCell>
      {React.Children.map(children, (child, index) =>
        index === 0 ? (
          <TextCell>{child}</TextCell>
        ) : (
          <InputCell>{child}</InputCell>
        ),
      )}
    </StyledTableCell>
  );
};

export default ColumnHeadCell;
